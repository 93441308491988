.content {
  color: var(--app-text-color);
  text-align: left;
  margin-bottom: 184px;
  padding: 50px 70px;
  // height: 407px;
  // background: var(--app-primary-color);
  border-radius: 28px;

  // background-image: url('/images/home/use-icon.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  h2 {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    // line-height: 50px;
    margin-bottom: 43px;
  }

  > .stepContainer {
    width: 74%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    > .cardContainer {
      margin-right: 18px;

      > .lineContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        > .lineImg {
          width: 34px;
          max-width: 34px;
          min-width: 34px;

          height: 34px;
          max-height: 34px;
          min-height: 34px;
          margin-right: 18px;
          display: block;
        }

        > .line {
          border-color: #ffffff;
          width: 100%;
          height: 1px;
          border: 1px dashed #fff;
        }
      }

      > .contentContainer {
        margin-top: 32px;

        > .title {
          font-size: 24px;
          font-weight: bold;
          color: #ffffff;
          line-height: 34px;
        }

        > .desc {
          font-size: 7px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
          margin-top: 7px;
        }
      }
    }
  }

  // .addAccount {
  //   margin-top: 40px;
  //   display: inline-flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 0 20px;
  //   height: 54px;
  //   border-radius: 27px;
  //   background-color: #fff;
  //   color: var(--app-primary-color);
  //   cursor: pointer;
  // }
}

@media screen and (min-width: 749px) {
  .content {
    > .stepContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      > .cardContainer {
        margin-right: 18px;

        > .lineContainer {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          > .lineImg {
            margin-right: 18px;
          }

          > .line {
            width: 100%;
            height: 1px;
          }
        }

        > .contentContainer {
          margin-top: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .content {
    > .stepContainer {
      display: block;
      // grid-template-columns: 1fr 1fr 1fr;

      > .cardContainer {
        display: flex;
        // justify-content: flex-start;
        // align-items: flex-start;

        margin-right: 0;
        margin-bottom: 18px;

        > .lineContainer {
          display: block;
          overflow: hidden;
          min-width: 34px;

          > .lineImg {
            margin-right: 0;
          }

          > .line {
            width: 1px;
            height: 100%;
            margin-left: 15px;
            margin-top: 5px;
          }
        }

        > .contentContainer {
          margin-left: 32px;
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 899px) {
  .content {
    margin-bottom: 184px;
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .content {
    margin-bottom: 154px;
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .content {
    margin-bottom: 119px;
  }
}

@media screen and (max-width: 577px) {
  .content {
    margin-bottom: 50px;
    padding: 24px;

    h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }

    > .stepContainer {
      width: 100%;
    }
  }
}

